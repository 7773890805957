<template>
  <data-list
    ref="dataList"
    class="content md"
    url="/admin/data-role"
    query="&sort=-id"
    entity-name="角色"
    add-btn="添加角色"
    add-route="CoreRoleAdd"
    hide-show-detail
    show-row-num
    :columns="columns"
    :search-key-type="searchKeyType"
    :requestConfig="{ noTempleFilter: true }"
    @edit="editRow"
  >
    <template v-slot:query="{ form }">
      <a-form-model-item>
        <a-input v-model="form.name" placeholder="输入角色名称查询" allowClear></a-input>
      </a-form-model-item>
    </template>
  </data-list>
</template>

<script>
export default {
  name: "Role",
  data() {
    return {
      searchKeyType: {
        name: 1,
      },
      columns: [
        { title: "角色名称", dataIndex: "name" },
        {
          title: "授权范围",
          customRender: (text) => {
            let original_visible_ids = text.original_visible_ids;
            let res = "无";
            if (original_visible_ids) {
              let books = JSON.parse(original_visible_ids);
              if (books?.length > 0) {
                res = books.map((b, i) => {
                  return (
                    <div class="book-item" key={i}>
                      {b.type == "tag" ? (
                        <div class="icon-tag book-icon"></div>
                      ) : (
                        <a-icon class="book-icon" type={b.type == "user" ? "user" : "folder"} />
                      )}
                      {b.type == "tag" ? (
                        <div class="book-name">{b.name}</div>
                      ) : (
                        <open-data class="book-name" type={b.type + "Name"} openid={b.id} />
                      )}
                    </div>
                  );
                });
              }
            }
            return res;
          },
        },
      ],
    };
  },

  methods: {
    editRow(form) {
      this.$router.push({ name: "RoleDetail", params: { id: form.id }, query: { edit: 1 } });
    },
  },
};
</script>

<style lang="less" scoped></style>
